<template>
  <div class="home-page">
    <div class="container">
      <div class="app-ads">
        <a
          v-if="siteConfig.ad && siteConfig.ad.cover"
          :href="siteConfig.ad && siteConfig.ad.url"
        >
          <img
            v-lazy="siteConfig.ad && siteConfig.ad.cover"
            alt=""
            class="app-ads__img"
          />
        </a>
      </div>
      <div class="app-goldenarea clearfix">
        <a-row type="flex" justify="space-between" align="top" :gutter="30">
          <a-col :span="17">
            <div class="app-goldenarea__main">
              <div class="app-swiper-container">
                <a-carousel
                  ref="goldenareaCarousel"
                  :arrows="true"
                  :autoplay="true"
                  class="app-banner image-radius"
                >
                  <div slot="prevArrow" class="swiper-prev">
                    <img src="~/assets/arrow-right.svg" />
                  </div>
                  <div slot="nextArrow" class="swiper-next">
                    <img src="~/assets/arrow-right.svg" />
                  </div>

                  <div
                    v-for="(item, i) in pcCarousels"
                    :key="item.id + i"
                    class="app-banner__item image-radius"
                  >
                    <p class="app-banner__title">{{ item.title }}</p>
                    <video
                      v-if="item.mediaType === 'VIDEO'"
                      controls
                      :src="item.video && item.video.url"
                      class="app-banner__img"
                    />
                    <a v-else :href="item.to" target="_blank">
                      <img
                        :src="item.cover && item.cover.url"
                        alt=""
                        class="app-banner__img"
                      />
                    </a>
                  </div>
                </a-carousel>
              </div>

              <div class="app-goldenarea__body">
                <app-panel
                  title="TOP推荐"
                  @click="handleMoreClick('/find/library/article')"
                >
                  <a-row
                    class="app-goods-list"
                    :gutter="{ xs: 0, sm: 0, md: 10, lg: 10 }"
                  >
                    <a-col
                      v-for="item in topRecommendedArticleList"
                      :key="item.id"
                      :xs="{ span: 24 }"
                      :sm="{ span: 24 }"
                      :md="{ span: 8 }"
                      :lg="{ span: 8 }"
                    >
                      <app-goods
                        :data-source="item"
                        width="100%"
                        :is-doctor="
                          item.userInfo && isAuthDoctor(item.userInfo.role)
                        "
                        :is-official="item.userInfo && item.userInfo.isOfficial"
                        :tag="true"
                        @click.native="goDetail(item)"
                        @tagClick="handleTagClick"
                      ></app-goods>
                    </a-col>
                  </a-row>
                </app-panel>
              </div>
            </div>
          </a-col>
          <a-col :span="7">
            <div class="app-goldenarea__messages">
              <div class="app-goldenarea__title">
                <span>//</span>
                <span>联盟圈</span>
              </div>
              <div
                class="app-goldenarea__messages__body"
                :style="{ height: Math.min(weiboWrapperHeight, 1348) + 'px' }"
              >
                <div
                  id="weibo-swiper"
                  class="app-comment__container"
                  :style="{ height: weiboWrapperHeight * 2 + 'px' }"
                  @mouseleave.self="startWeiboSwiper"
                >
                  <div :class="`app-comment__item `">
                    <app-step
                      v-for="item in weiboList"
                      :key="item.id"
                      :data-source="item"
                      @avatarClick="handleAvatarClick(item)"
                      @conClick="handleConClick(item)"
                      @mouseover.native.stop="stopWeiboSwiper"
                    ></app-step>
                  </div>
                  <div :class="`app-comment__item `">
                    <app-step
                      v-for="item in weiboList"
                      :key="item.id + 'bak'"
                      :data-source="item"
                      @avatarClick="handleAvatarClick(item)"
                      @conClick="handleConClick(item)"
                      @mouseover.native.stop="stopWeiboSwiper"
                    ></app-step>
                  </div>
                </div>
                <!-- -->
              </div>
              <load-more
                style="padding:40px 0;"
                text="查看更多"
                @load-more="goUnion"
              ></load-more>
            </div>
          </a-col>
        </a-row>
      </div>
      <div class="app-goldenarea-mobile">
        <div class="app-swiper-container">
          <a-carousel
            ref="goldenareaCarousel"
            :arrows="true"
            :autoplay="true"
            class="app-banner"
          >
            <div slot="prevArrow" class="swiper-prev">
              <img src="~/assets/arrow-right.svg" />
            </div>
            <div slot="nextArrow" class="swiper-next">
              <img src="~/assets/arrow-right.svg" />
            </div>

            <div
              v-for="(item, i) in mobileCarousels"
              :key="item.id + i"
              class="app-banner__item"
              @touchstart="handleLink(item.to)"
            >
              <p class="app-banner__title">{{ item.title }}</p>
              <video
                v-if="item.mediaType === 'VIDEO'"
                controls
                :src="item.video && item.video.url"
                class="app-banner__img"
              />
              <a v-else :href="item.to" target="_blank">
                <img
                  :src="item.cover && item.cover.url"
                  alt=""
                  class="app-banner__img"
                />
              </a>
            </div>
          </a-carousel>
        </div>
        <div class="app-goldenarea__body">
          <app-panel
            title="TOP推荐"
            @click="handleMoreClick('/find/library/article')"
          >
            <a-row
              class="app-goods-list"
              :gutter="{ xs: 0, sm: 0, md: 10, lg: 10 }"
            >
              <a-col
                v-for="item in topRecommendedArticleList"
                :key="item.id"
                :xs="{ span: 24 }"
                :sm="{ span: 24 }"
                :md="{ span: 8 }"
                :lg="{ span: 8 }"
              >
                <app-goods
                  :data-source="item"
                  width="100%"
                  :is-doctor="item.userInfo && isAuthDoctor(item.userInfo.role)"
                  :is-official="item.userInfo && item.userInfo.isOfficial"
                  :tag="true"
                  @click.native="goDetail(item)"
                  @tagClick="handleTagClick"
                ></app-goods>
              </a-col>
            </a-row>
          </app-panel>
        </div>
      </div>
    </div>
    <div class="app-zhuanlan">
      <div class="container">
        <app-title
          title="专栏"
          title-color="#fff"
          btn-color="#fff"
          @click="handleSpecMoreClick"
        ></app-title>
        <a-carousel ref="goldenareaCarousel" :arrows="true" :autoplay="true">
          <!-- <div slot="prevArrow" class="swiper-prev">
            <img src="~/assets/arrow-right.svg" />
          </div>
          <div slot="nextArrow" class="swiper-next">
            <img src="~/assets/arrow-right.svg" />
          </div> -->
          <div v-for="item in specColumnData" :key="item.id">
            <div class="app-zhuanlan-content">
              <div class="app-zhuanlan-left">
                <template v-if="item && item.id">
                  <h3 class="app-zhuanlan-title">{{ item.title }}</h3>
                  <div class="app-zhuanlan-line">
                    <span class="line-content"></span>
                  </div>
                  <p class="app-zhuanlan-desc">
                    {{ item.description }}
                  </p>
                  <app-button
                    class="app-zhuanlan-btn"
                    @click="handleSpecDetail(item.id)"
                    >进入专栏</app-button
                  >
                </template>
              </div>
              <div class="app-zhuanlan-right">
                <img
                  v-if="item.icon"
                  v-lazy="compressImg(item.icon, '580')"
                  class="image-radius"
                  alt=""
                />
                <img
                  v-else
                  src="~/assets/zhuanlan2.png"
                  class="image-radius"
                  alt=""
                />
              </div>
            </div>
          </div>
        </a-carousel>
      </div>
    </div>
    <div class="app-new">
      <div class="container">
        <app-title
          title="最新"
          @click="handleMoreClick('/find/news/article')"
        ></app-title>
        <a-row class="app-new-list">
          <a-col
            v-for="item in newNewsList"
            :key="item.id"
            :md="{ span: 6 }"
            :lg="{ span: 6 }"
          >
            <app-new-card
              :title="item.title"
              :content="item.abstract"
              :time="dateFormat(item.createdAt, 'YYYY-MM-DD HH:mm:ss')"
              @click.native="goDetail(item)"
            ></app-new-card>
          </a-col>
        </a-row>
      </div>
    </div>
    <div class="app-guide">
      <div class="container">
        <app-title
          title="指南共识"
          title-color="#fff"
          btn-color="#fff"
          @click="
            handleMoreClick(
              `/find/library?active=${encodeURIComponent('指南共识')}`
            )
          "
        ></app-title>
        <a-row class="app-guide-list" :gutter="4">
          <a-col
            v-for="item in libraryDataSource.guidDataList"
            :key="item.id"
            class="app-guide-card-wrapper"
            :sm="{ span: 24 }"
            :md="{ span: 6 }"
            :lg="{ span: 6 }"
          >
            <app-guide-card
              :id="item.id"
              :title="item.title"
              :content="item.abstract"
              :time="dateFormat(item.createdAt, 'YYYY-MM-DD HH:mm')"
            ></app-guide-card>
          </a-col>
        </a-row>
      </div>
    </div>
    <div class="app-course">
      <div class="container">
        <app-title
          title="公开课"
          @click="
            handleMoreClick(
              `/find/library?active=${encodeURIComponent('公开课')}`
            )
          "
        ></app-title>
        <a-row class="app-course-list">
          <a-col
            v-for="item in libraryDataSource.publicDataList"
            :key="item.id"
            :xs="{ span: 12 }"
            :sm="{ span: 12 }"
            :md="{ span: 6 }"
            :lg="{ span: 6 }"
          >
            <app-course-card
              :id="item.id"
              :avatar="item.thumbnail"
              :title="item.title"
              :content="item.abstract"
            ></app-course-card>
          </a-col>
        </a-row>
      </div>
    </div>
    <div v-if="libraryDataSource.caseDataList.length > 0" class="app-zhuanlan">
      <div class="container">
        <app-title
          title="病例"
          title-color="#fff"
          btn-color="#fff"
          @click="
            handleMoreClick(
              `/find/library?active=${encodeURIComponent('病例')}`
            )
          "
        ></app-title>
        <a-carousel ref="goldenareaCarousel" :arrows="false" :autoplay="true">
          <!-- <div slot="prevArrow" class="swiper-prev">
            <img src="~/assets/arrow-right.svg" />
          </div>
          <div slot="nextArrow" class="swiper-next">
            <img src="~/assets/arrow-right.svg" />
          </div> -->
          <div v-for="item in libraryDataSource.caseDataList" :key="item.id">
            <div class="app-zhuanlan-content">
              <div class="app-zhuanlan-left">
                <h3 class="app-zhuanlan-title">
                  {{ item.title }}
                </h3>
                <div class="app-zhuanlan-line">
                  <span class="line-content"></span>
                </div>
                <p class="app-zhuanlan-desc">
                  {{ item.abstract }}
                </p>
                <app-button
                  class="app-zhuanlan-btn bingli"
                  type="primary"
                  @click="goDetail(item)"
                  >查看详情</app-button
                >
              </div>
              <div class="app-zhuanlan-right">
                <img
                  v-lazy="compressImg(item.thumbnail, '580')"
                  class="image-radius"
                  alt=""
                />
              </div>
            </div>
          </div>
        </a-carousel>
      </div>
    </div>
    <div class="app-recommend">
      <div class="container">
        <app-title
          title="热点"
          @click="handleMoreClick('/find/library')"
        ></app-title>
        <a-row
          class="app-goods-list"
          :gutter="{ xs: 0, sm: 0, md: 40, lg: 60 }"
        >
          <a-col
            v-for="item in transformHotArticleList"
            :key="item.id"
            :xs="{ span: 24 }"
            :sm="{ span: 24 }"
            :md="{ span: 8 }"
            :lg="{ span: 8 }"
          >
            <app-goods
              :data-source="item"
              width="100%"
              :is-doctor="item.userInfo && isAuthDoctor(item.userInfo.role)"
              :is-official="item.userInfo && item.userInfo.isOfficial"
              :tag="true"
              @click.native="goDetail(item)"
              @tagClick="handleTagClick"
            ></app-goods>
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import _ from 'lodash'
import articleService from '@/services/articles'
import carouselsService from '@/services/carousels'
import siteService from '@/services/siteConfigurations'
import weiboService from '@/services/weibo'
import { ARTICLEKIND, PLATFORM } from '@/utilities/constants'
import { move } from '@/utilities/move'

export default {
  asyncData() {
    const actions = [
      articleService.fetchArticleList(ARTICLEKIND.NEWS, {
        showInHomepage: true,
      }),
      articleService.fetchArticleList(ARTICLEKIND.ALL, {
        isRecommended: true,
        showInHomepage: true,
      }),
      articleService.fetchSpecArticleList({ showInHomepage: true }),
      articleService.fetchArticleList(ARTICLEKIND.LIBRARY, {
        isHot: true,
        showInHomepage: true,
      }),
      carouselsService.fetchCarouselList(),
      siteService.fetchConfigurations(),
      weiboService.fetchWeiboList(),
    ]

    return Promise.all(actions).then(response => {
      const newNewsList = response[0] && response[0].data
      const libraryList = response[1] && response[1].data
      const specColumnData = (response[2] && response[2].data) || []

      return {
        newNewsList: _.slice(newNewsList, 0, 4),
        libraryList,
        specColumnData,
        carousels: response[4] || [],
        siteInfo: response[5].siteInfo || {},
        weiboList: response[6].data || [],
      }
    })
  },
  data() {
    return {
      autoplay: true,
      carousels: [],
      libraryDataSource: {
        publicDataList: [],
        guidDataList: [],
        caseDataList: [],
      },
      newNewsList: [],
      libraryList: [],
      specColumnData: {},
      hotLibraryList: [],
      goodsItem: {
        title: '文章的大标题，关于近视眼的预防，我们应该...',
        user: {
          avatar:
            'http://t9.baidu.com/it/u=583874135,70653437&fm=79&app=86&f=JPEG?w=3607&h=2408',
          name: '熊博士',
        },
        thumbnail:
          'http://t9.baidu.com/it/u=583874135,70653437&fm=79&app=86&f=JPEG?w=3607&h=2408',
        time: '10分钟前',
        num: 10,
      },
      weiboList: [],
      weiboWrapperHeight: 1000,
      isScrolling: true,
      timer: null,
      weiboSwiperData: {
        timer: null,
        el: null,
      },
      siteInfo: {},
    }
  },
  computed: {
    ...mapState({
      siteConfig: state => state['site-config'].configInfo || {},
    }),
    ...mapGetters('articles', ['libraryCategoryList']),
    topRecommendedArticleList() {
      return _.slice(
        _.map(this.libraryList, item => {
          return _.defaults(
            {
              id: item.id,
              title: item.title,
              user: {
                avatar: item.userInfo && item.userInfo.avatarUrl,
                name: item.userInfo && item.userInfo.nickname,
              },
              thumbnail: item.cover,
              time: this.timeAgo(item.createdAt),
              num: item.commentCount,
              createdAt: item.createdAt,
              tags: item.tags,
              abstract: item.descriptions,
              content: item.content,
            },
            item
          )
        }),
        0,
        9
      )
    },
    transformHotArticleList() {
      return _.slice(
        _.map(this.hotLibraryList, item => {
          return _.defaults(
            {
              id: item.id,
              title: item.title,
              user: {
                avatar: item.userInfo && item.userInfo.avatarUrl,
                name: item.userInfo && item.userInfo.nickname,
              },
              thumbnail: item.cover,
              time: this.timeAgo(item.createdAt),
              num: item.commentCount,
              createdAt: item.createdAt,
              tags: item.tags,
              abstract: item.descriptions,
              content: item.content,
            },
            item
          )
        }),
        0,
        9
      )
    },
    swiperBannerOption() {
      if (this.carousels.length <= 1) {
        return {
          autoplay: false,
          loop: false,
          noSwiping: true,
          pagination: {
            el: '.swiper-pc-pagination',
            clickable: true,
          },
        }
      } else {
        return {
          autoplay: {
            delay: 5000,
          },
          loop: true,
          navigation: {
            nextEl: '.swiper-pc-button-next',
            prevEl: '.swiper-pc-button-prev',
          },
          pagination: {
            el: '.swiper-pc-pagination',
            clickable: true,
          },
        }
      }
    },
    swiperMobileBannerOption() {
      if (this.carousels.length <= 1) {
        return {
          autoplay: false,
          loop: false,
          noSwiping: true,
          pagination: {
            el: '.swiper-mobile-pagination',
          },
        }
      } else {
        return {
          autoplay: {
            delay: 5000,
          },
          // autoplay: false,
          loop: true,
          navigation: {
            nextEl: '.swiper-mobile-button-next',
            prevEl: '.swiper-mobile-button-prev',
          },
          pagination: {
            el: '.swiper-mobile-pagination',
          },
        }
      }
    },
    swiperOption() {
      return {
        direction: 'vertical',
        // height:,
        spaceBetween: 0,
        slidesPerView: 1,
        slidesPerGroup: 1,
        watchSlidesVisibility: true, // 防止不可点击
        watchSlidesProgress: true,
        allowTouchMove: false,
        autoplay: {
          delay: 0,
        },
        freeMode: true,
        loop: true,
        speed: this.isScrolling ? 12000 : 0,
      }
    },
    weiboSwiperStyle() {
      return this.isScrolling
        ? 'transition-timing-function: linear;'
        : 'transition-timing-function: linear;transition-duration:0;'
    },
    pcCarousels() {
      return _.filter(this.carousels, v => v.platform !== PLATFORM.MOBILE)
    },
    mobileCarousels() {
      return _.filter(this.carousels, v => v.platform !== PLATFORM.PC)
    },
  },
  beforeDestroy() {
    this.stopWeiboSwiper()
  },
  mounted() {
    let weiboWrapperHeight = 0

    const weiboConEl = document.getElementsByClassName('app-comment__item')[0]

    if (weiboConEl) {
      const appStepsEl = weiboConEl.getElementsByClassName('app-step')
      _.each(appStepsEl, el => {
        weiboWrapperHeight += el.offsetHeight
      })
    }

    this.weiboWrapperHeight = weiboWrapperHeight

    this.$nextTick(() => {
      this.playWeiboSwiper()
    })

    this.getLibraryCategoryList()
      .then(() => {
        return this.getArticleList()
      })
      .catch(err => {
        this.$message.error(err.message)
      })

    articleService
      .fetchArticleList(ARTICLEKIND.LIBRARY, {
        isHot: true,
        showInHomepage: true,
      })
      .then(res => {
        this.hotLibraryList = res.data
      })
      .catch(err => {
        this.$message.error(err.message)
      })
  },
  methods: {
    ...mapActions('articles', ['getLibraryCategoryList']),
    handleMoreClick(path) {
      if (!path) {
        return
      }
      this.$router.push(path)
    },
    handleSpecMoreClick() {
      this.$router.push(`/find/column`)
    },
    handleSpecDetail(id) {
      if (!id) {
        this.$message.error('获取专栏数据出错')
        return
      }

      this.$router.push(`/column-detail/${id}`)
    },
    goDetail(item) {
      if (!item.id) {
        return
      }

      this.$router.push({
        path: `/article-detail/${item.id}`,
        query: {
          kind: item.kind,
        },
      })
    },
    getArticleList() {
      const libraryDataSource = {
        publicDataList: [],
        guidDataList: [],
        caseDataList: [],
      }
      const actions = _.map(this.libraryCategoryList, item => {
        return articleService.fetchArticleList(ARTICLEKIND.ALL, {
          category: item.id,
          showInHomepage: true,
        })
      })

      Promise.all(actions)
        .then(res => {
          _.each(res, (resItem, index) => {
            const category = (this.libraryCategoryList[index] || {}).text

            if (resItem.data.length) {
              switch (category) {
                case '公开课':
                  libraryDataSource.publicDataList = _.slice(
                    _.map(resItem.data, item => {
                      return _.defaults(
                        {
                          id: item.id,
                          title: item.title,
                          user: {
                            avatar: item.userInfo && item.userInfo.avatarUrl,
                            name: item.userInfo && item.userInfo.nickname,
                          },
                          thumbnail: item.cover,
                          time: this.timeAgo(item.createdAt),
                          num: item.commentCount,
                          createdAt: item.createdAt,
                          tags: item.tags,
                          abstract: item.descriptions,
                          content: item.content,
                        },
                        item
                      )
                    }),
                    0,
                    4
                  )
                  break
                case '指南共识':
                  libraryDataSource.guidDataList = _.slice(
                    _.map(resItem.data, item => {
                      return _.defaults(
                        {
                          id: item.id,
                          title: item.title,
                          user: {
                            avatar: item.userInfo && item.userInfo.avatarUrl,
                            name: item.userInfo && item.userInfo.nickname,
                          },
                          thumbnail: item.cover,
                          time: this.timeAgo(item.createdAt),
                          num: item.commentCount,
                          createdAt: item.createdAt,
                          tags: item.tags,
                          abstract: item.descriptions,
                          content: item.content,
                        },
                        item
                      )
                    }),
                    0,
                    4
                  )
                  break
                case '病例':
                  libraryDataSource.caseDataList = _.map(resItem.data, item => {
                    return _.defaults(
                      {
                        id: item.id,
                        title: item.title,
                        user: {
                          avatar: item.userInfo && item.userInfo.avatarUrl,
                          name: item.userInfo && item.userInfo.nickname,
                        },
                        thumbnail: item.cover,
                        time: this.timeAgo(item.createdAt),
                        num: item.commentCount,
                        createdAt: item.createdAt,
                        tags: item.tags,
                        abstract: item.descriptions,
                        content: item.content,
                      },
                      item
                    )
                  })
                  break
              }
            }
          })

          this.libraryDataSource = libraryDataSource
        })
        .catch(err => {
          this.$message.error(err.message)
        })
    },
    goUnion() {
      this.$router.push('/union')
    },
    handleAvatarClick(item) {
      if (item && item.userInfo && item.userInfo.id) {
        this.$router.push(`/personal-center/weibo/${item.userInfo.id}`)
      }
    },
    stopSwiper() {
      this.autoplay = false
      // this.myBannerSwiper && this.myBannerSwiper.autoplay.stop()
    },
    startSwiper() {
      if (this.carousels.length > 1) {
        this.autoplay = true
        // this.myBannerSwiper &&
        //   this.myBannerSwiper.autoplay &&
        //   this.myBannerSwiper.autoplay.start()
      }
    },
    stopWeiboSwiper() {
      this.isScrolling = false
      if (this.weiboSwiperData.timer) {
        clearInterval(this.weiboSwiperData.timer)
      }
    },
    startWeiboSwiper() {
      this.isScrolling = true
      this.playWeiboSwiper()
    },
    handleTagClick(text) {
      if (text) {
        this.$router.push(`/search-result?keyword=${text}`)
      }
    },
    playWeiboSwiper() {
      const el = document.getElementById('weibo-swiper')
      const interval = 25000

      this.weiboSwiperData.el = el

      move(
        this.weiboSwiperData,
        'top',
        -this.weiboWrapperHeight,
        Math.max((this.weiboWrapperHeight / interval) * 20, 1),
        () => {
          el.style.top = 0
          if (this.isScrolling) {
            this.playWeiboSwiper()
          } else if (el.timer) {
            clearInterval(el.timer)
          }
        }
      )
    },
    handleLink(url) {
      if (url) {
        window.location.href = url
      }
    },
    handleConClick(item) {
      if (item && item.id) {
        this.$router.push(`/union#comment${item.id}`)
      }
    },
  },
  head() {
    const { description, keywords, title } = this.siteInfo
    return {
      title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description,
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: keywords,
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~/styles/variables';
@import '~/styles/hairline';

.swiper-button {
  width: 20px;
  height: 20px;
  z-index: 10;
  cursor: pointer;
  background-size: 20px 20px;
  background-position: center;
  background-repeat: no-repeat;
}

.swiper-prev {
  // background-image: url('../assets/arrow-right.svg');
  transform: rotate(-180deg);
  cursor: pointer;
  left: 10px;
}

.swiper-next {
  // background-image: url('../assets/arrow-right.svg');
  cursor: pointer;
  right: 10px;
}

.swiper-prev,
.swiper-next {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-size: 22px;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 10;
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin-top: -22px;
  img {
    width: 24px;
    height: 24px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }
  &::before {
    display: none;
  }
}

::v-deep {
  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    margin: 0 8px;
    background: #fff;
    opacity: 1;
    cursor: pointer;
  }
}

::v-deep .swiper-pagination-bullet-active {
  background: $--color-primary;
}

.swiper-pagination {
  width: 100%;

  bottom: 8px;
}

.app-ads {
  overflow: hidden;
  margin: 30px 0;

  &__img {
    width: 100%;
    height: 70px;
    @include object-fit(cover);
    background: #f0f0f0;
    overflow: hidden;
  }
}
.app-banner {
  width: 100%;
  overflow: hidden;

  &__title {
    font-weight: bold;
    line-height: 1.5;
    white-space: pre-line;
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    padding: 0px 20px;
    font-size: 20px;
    max-height: 60px;
    color: #fff;
    overflow: hidden;

    @include text-ellipsis(2);
  }

  &__item {
    height: 452px;
    // height: 410px;
    position: relative;
    overflow: hidden;
    display: inline-block;
  }

  &__img {
    width: 100%;
    height: 100%;
    position: static;
    @include object-fit(cover);
  }
  &__dots {
  }
}

.app-goldenarea {
  width: 100%;
  overflow: hidden;
  &-mobile {
    display: none;
  }
  &__main {
    width: 100%;
    // float: left;
  }

  &__body {
    padding: 57px 0 50px;
  }

  &__messages {
    // width: 264px;
    // float: right;

    &__body {
      height: 1500px;
      overflow: auto;
      padding: 36px 0 0;
      position: relative;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__title {
    padding: 20px 40px;
    font-size: 26px;
    font-weight: 600;
    line-height: 36px;
    color: #fff;
    background: $--color-primary;
  }
}

// .app-goods-list {

// }
.app-zhuanlan {
  background-color: #545454;
  padding-top: 60px;
  &-content {
    display: flex;
    justify-content: flex-start;
    padding-bottom: 60px;
  }
  &-left {
    color: #fff;
    padding-right: 80px;
    padding-top: 22px;
    text-align: center;
    width: 500px;
    flex: 0 0 auto;
  }
  &-right {
    padding-top: 22px;
    flex: 1 1 auto;
    img {
      width: 100%;
      height: 387px;
      @include object-fit(contain);
    }
  }
  &-title {
    padding: 28px 0 38px;
    color: #fff;
    font-weight: 600;
    font-size: 28px;
    margin-bottom: 0;
  }
  &-line {
    display: flex;
    align-items: center;
    justify-content: center;
    .line-content {
      width: 65px;
      height: 3px;
      background: #fff;
    }
  }
  &-desc {
    font-size: 12px;
    margin-top: 28px;
    margin-bottom: 0;
    height: 110px;
    @include text-ellipsis(6);
    text-align: center;
  }
  &-btn {
    width: 98px;
    height: 40px;
    line-height: 40px;
    color: $--color-primary;
    background: #fff;
    margin-top: 76px;
    &.bingli {
      background: $--color-primary;
      color: #fff;
    }
  }
}
.app-new {
  background-color: #fff;
  padding-top: 60px;
  padding-bottom: 60px;
  &-list {
    margin-top: 40px;
  }
}
.app-guide {
  background-color: #545454;
  padding-top: 60px;
  padding-bottom: 60px;
  &-list {
    background: #f4f4f4;
    margin-top: 40px;
  }
  &-card-wrapper {
    transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.3s;
    &:hover {
      background: $--color-primary;
    }
  }
}
.app-course {
  background-color: #fff;
  padding-top: 60px;
  padding-bottom: 60px;
  &-list {
    // background: #f4f4f4;
    margin-top: 40px;
  }
}
.app-recommend {
  background-color: #fff;
  padding-top: 60px;
  padding-bottom: 60px;
  &-list {
    margin-top: 40px;
  }
}

.app-comment {
  &__container {
    position: absolute;
    left: 0;
  }

  &__item {
    width: 100%;
  }
}

.app-swiper-container {
  position: relative;
  // width: 685px;
  width: 756px;
  max-width: 100%;
}

@media (min-width: 1440px) {
  // .app-goldenarea {
  //   &__main {
  //     width: 756px;
  //   }
  // }

  .app-swiper-container {
    width: 756px;
  }

  .app-banner__item {
    height: 452px;
  }
}

@media (max-width: 980px) {
  // .app-goldenarea {
  //   &__main {
  //     width: 700px;
  //   }
  // }

  .app-swiper-container {
    width: 700px;
  }

  .app-banner__item {
    height: 418px;
  }
}

@media (max-width: 768px) {
  .home-page {
    .container {
      padding: 0;
    }
  }
  .app-ads {
    margin: 10px 0;

    &__img {
      height: 35px;
    }
  }
  .app-banner {
    padding: 0;
    // height: 162px;
    &__item {
      height: 162px;
      overflow: hidden;
    }
    &__img {
      width: 100%;
      height: auto;
      position: static;
      @include object-fit(cover);
    }

    &__title {
      bottom: 10px;
      padding: 0px 10px;
      font-size: 12px;
      max-height: 36px;
      color: #fff;
      overflow: hidden;

      @include text-ellipsis(2);
    }
  }

  .app-goldenarea {
    display: none;
    &-mobile {
      display: block;
      margin-top: 10px;
      padding: 0 15px;
    }
    &__body {
      padding: 0;
    }
  }

  .app-goods-list {
    display: block;
    padding-bottom: 0;
  }
  .app-zhuanlan {
    background-color: #545454;
    padding: 0 15px 0;
    &-content {
      flex-direction: column-reverse;
      padding-bottom: 35px;
    }
    &-left {
      color: #fff;
      padding-right: 0;
      padding-top: 25px;
      text-align: center;
      width: 100%;
    }
    &-right {
      padding-top: 0;
      img {
        width: 100%;
        height: 185px;
        @include object-fit(contain);
      }
    }
    &-title {
      display: none;
    }
    &-line {
      display: none;
    }
    &-desc {
      font-size: 12px;
      margin-top: 25px;
      text-align: left;
      margin-bottom: 0;
      height: auto;
      @include text-ellipsis(3);
    }
    &-btn {
      width: 98px;
      height: 28px;
      line-height: 28px;
      margin-top: 20px;
      &.bingli {
        background: #fff;
        color: $--color-primary;
        border-color: transparent;
      }
    }
  }
  .app-new {
    background-color: #fff;
    padding: 0 15px 28px;
    &-list {
      margin-top: 0;
    }
  }
  .app-guide {
    background-color: #545454;
    padding: 0 15px 28px;
    &-list {
      background: transparent;
      margin-top: 0;
    }
  }
  .app-course {
    background-color: #fff;
    padding: 0 15px 8px;
    &-list {
      margin-top: 0;
    }
  }
  .app-recommend {
    background-color: #fff;
    padding: 0 15px 28px;
    &-list {
      margin-top: 0px;
    }
  }

  .app-swiper-container {
    width: 100%;
    overflow: hidden;
  }
}
</style>
