// 参数1：要执行动画的对象（obj）;参数2：要执行动画的样式,可以是left,right,top等（attr）；参数3：执行动画的目标位置（target）；参数4：表示移动的速度正数向右移动，负数向左移动（speed）；参数5：回调函数（callback），这个函数将会在动画执行完毕以后执行
export function move(obj, attr, target, speed, callback) {
  // 在开启一个定时器之前，先关闭上一个元素的定时器
  clearInterval(obj.timer)
  // 获取元素目前的位置
  const current = parseInt(getStyle(obj.el, attr)) || 0
  // 判断速度的正负
  if (current > target) {
    speed = -speed
  }

  obj.timer = setInterval(function() {
    // 获取div的原来的值
    const oldvalue = parseInt(getStyle(obj.el, attr)) || 0
    // 在旧值的基础上增加
    let newvalue = oldvalue + speed

    if ((speed < 0 && newvalue < target) || (speed > 0 && newvalue > target)) {
      newvalue = target
    }
    // 将新值赋给box
    obj.el.style[attr] = newvalue + 'px'

    if (newvalue === target) {
      // 达到目标关闭定时器
      clearInterval(obj.timer)
      callback && callback()
    }
  }, 20)
}

function getStyle(obj, name) {
  if (window.getComputedStyle) {
    // 正常浏览器有getComputedStyle()方法
    return getComputedStyle(obj, null)[name]
  } else {
    // IE8的浏览器没有getComputedStyle()方法
    return obj.currentStyle[name]
  }
}
